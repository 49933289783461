import { Card, Stack, Typography } from "@mui/material";
import { AutoAwesome } from "@mui/icons-material";

export default function VibeCheck({ vibe }) {
  return (
    <Card
      sx={{
        borderRadius: 3,
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <AutoAwesome sx={{ fontSize: "1.3rem", color: "#04FFFF" }} />
        <Typography variant="caption" sx={{ fontWeight: 600, opacity: 0.7 }}>
          VIBE CHECK
        </Typography>
      </Stack>

      <Typography variant="body1" sx={{ color: "text.secondary" }}>
        {vibe}
      </Typography>
    </Card>
  );
}
